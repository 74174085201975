import { InputGroupText } from 'reactstrap'

import { faBan, faCalculator, faCoins, faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BadgeWithTooltip from 'components/modules/badge/badge_with_tooltip'
import ValidatedInput from 'components/modules/inputs/validated-input'
import { CalculationComponent } from 'components/ui/calculatedVolumeUnits/calculationComponent'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

const VolumeUnitCostInput = ({
  onChange,
  value,
  calculatedVolumeUnitCosts,
  duplicationJobsRunning = false,
  componentLocale,
  globalLocale
}) => {
  const onChangeWithAbsoluteValue = event => {
    onChange({ target: { id: event.target.id, value: Math.abs(parseFloat(event.target.value) || 0) } })
  }

  const couldNotBeCalculated = calculatedVolumeUnitCosts.value === null

  return (
    <ValidatedInput
      id="event.volumeUnitCost"
      type="number"
      step={0.25}
      locale={globalLocale}
      label_sm="4" // NOTE: sizing according to the other inputs in the same form
      input_sm="5" // NOTE: sizing according to the other inputs in the same form
      onChange={onChangeWithAbsoluteValue}
      variables={{ event: { volumeUnitCost: value } }} // HACK: make atomic value compatible with the hacky interface of the ValidatedInput
      unit_de={
        <InputGroupText>
          <FontAwesomeIcon icon={faCoins} />
        </InputGroupText>
      }
      addon={
        <InputGroupText>
          {duplicationJobsRunning && (
            <BadgeWithTooltip tooltip={componentLocale.pendingFormDuplication} color="warning" className="mr-2" pill>
              <FontAwesomeIcon icon={faHourglassHalf} />
            </BadgeWithTooltip>
          )}

          <BadgeWithTooltip
            tooltip={couldNotBeCalculated ? componentLocale.costCannotBeCalculated : componentLocale.calculatedCost}
            color={couldNotBeCalculated ? 'warning' : 'primary-light'}
            pill
            className="mr-2"
          >
            <FontAwesomeIcon
              icon={couldNotBeCalculated ? faBan : faCalculator}
              className={couldNotBeCalculated ? '' : 'mr-2'}
            />
            {calculatedVolumeUnitCosts.value}
          </BadgeWithTooltip>

          {calculatedVolumeUnitCosts.calculationComponents.map(({ type, value }) => (
            <CalculationComponent key={`${type}:${value}`} {...{ type, value }} />
          ))}
        </InputGroupText>
      }
    />
  )
}

VolumeUnitCostInput.propTypes = {
  onChange: PropTypes.func.isRequired, // accepts an event object with at least target.id and target.value
  value: PropTypes.number,
  calculatedVolumeUnitCosts: PropTypes.shape({
    value: PropTypes.number,
    calculationComponents: PropTypes.arrayOf(PropTypes.shape(CalculationComponent.propTypes)).isRequired
  }).isRequired,
  duplicationJobsRunning: PropTypes.bool,
  componentLocale: PropTypes.shape({
    pendingFormDuplication: PropTypes.string.isRequired,
    calculatedCost: PropTypes.string.isRequired,
    costCannotBeCalculated: PropTypes.string.isRequired
  }).isRequired,
  globalLocale: PropTypes.shape({
    event: PropTypes.shape({
      volumeUnitCost: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default withLocale(
  withLocale(VolumeUnitCostInput, { key: 'event.volumeUnitCostAddons', attributeName: 'componentLocale' }),
  { attributeName: 'globalLocale' }
)
